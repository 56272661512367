<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CIcon name="cil-spreadsheet" />
            <strong> Language</strong>
          </CCardHeader>
          <CCardBody>
            <TB :tb_config="tb">
              <e-columns>
                <e-column
                  field="name"
                  headerText="name"
                  type="string"
                ></e-column>
                <e-column
                  field="iso_639_1"
                  headerText="iso_639_1"
                  type="string"
                ></e-column>
                <e-column field="status" headerText="stato"></e-column>
              </e-columns>
            </TB>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import { DataManager } from "@syncfusion/ej2-data";
import DrfAdaptor from "../../../http/EJ2_adapter";

import TB from "../../../common/TB";
import { apiUrl } from "../../../http";

export default Vue.extend({
  name: "Language",
  components: {
    TB,
  },
  data() {
    return {
      tb: {
        data: new DataManager({
          url: apiUrl("/api/geo/language/"),
          adaptor: new DrfAdaptor(),
          crossDomain: true,
        }),
      },
    };
  },
});
</script>
